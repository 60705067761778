import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import Navigation from './navigation';

export default function App() {
  return (
    <Router>
      <Navigation />
    </Router>
  );
}