import { Button, Col, Form, Input, notification, Row, Space, } from "antd";
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import ajaxService from "../../services/ajax-services";

const ChangePasswordForm = () => {

    const [form] = Form.useForm();
    let history = useHistory()
    const user_id = localStorage.getItem('loggedInUser')

    const onFinish = async (values) => {

        let response = await ajaxService.put('users/', values, user_id)
        if (response) {
            history.push({ pathname: "/" });
            notification.open({ message: 'Password has been updated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: 'Unable to updated password', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    }

    return (
        <>
            <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
                initialValues={{
                    active: true
                }}>
                <Row gutter={16} >
                    <Col span={6}></Col>
                    <Col span={2}></Col>
                    <Col span={12} >
                        <Row gutter={16}>

                            <Form.Item
                                labelCol={{ span: 24 }}
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        min: 8,
                                        message: "Password must be at least 8 characters",
                                    },
                                ]}>
                                <Input.Password />
                            </Form.Item>
                            <Col span={1}></Col>
                            <Form.Item
                                labelCol={{ span: 24 }}
                                label="Confirm Password"
                                name="confirm_password"
                                dependencies={['password']}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error("Confirm password does not match"));
                                        },
                                    }),
                                ]}>
                                <Input.Password />
                            </Form.Item>
                        </Row>
                        <Row>
                            <Form.Item>
                                <Space>
                                    <Button htmlType="submit" type="primary">Submit</Button>
                                    <Button onClick={() => history.push({ pathname: '/' })}>Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Row>
                    </Col>
                    <Col span={6}></Col>
                </Row>
            </Form>
        </>
    )
}

export default ChangePasswordForm