
import { Button, Card, Modal, notification, Space, Switch, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CheckCircleOutlined, CheckOutlined, CloseOutlined, CloseCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import ajaxService from '../../services/ajax-services';
import { useWindowSize } from '../../hooks';

const { Title, Text } = Typography

function Categories() {

    const { width } = useWindowSize()

    const isMobile = width <= 768
    const [showModal, setShowModal] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [value, setValue] = useState('')
    let history = useHistory()

    const updateValue = async (id, val, type) => {
        const active = await ajaxService.put('categories/', { type: type, value: val }, id)
        if (active) {
            if (val === true) {
                initialize()
                notification.open({ message: 'Category activated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else if (val === false) {
                notification.open({ message: 'Category deactivated', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            }
        }
        else {
            notification.open({ message: 'Unable to update active', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'title',
            width: '75%',
        },
        {
            title: 'Active',
            dataIndex: 'active',
            key: 'active',
            render: (text, record) => {
                return (
                    <Switch id={record.id}
                        onChange={(val) => updateValue(record.id, val, 'active')}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked={record.active ? true : false}
                    />
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return (
                    <Space size='small'>
                        <Button type='primary' onClick={() => history.push({ pathname: '/categories/form/' + record.id })}>Edit</Button>
                        <Button type='primary' danger onClick={() => openDeleteModal(record.id)}>Delete</Button>
                    </Space>
                )
            }
        },
    ]

    const deleteRow = async () => {
        let category_id = localStorage.getItem('category')
        let values = {
            is_delete: 1
        }
        let item = await ajaxService.put('categories/', values, category_id)
        if (item) {
            initialize()
            setShowModal(false)
            notification.open({ message: 'Category has been deleted successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        }
    }

    const openDeleteModal = (id) => {
        localStorage.setItem('category', id);
        setShowModal(true)
    }

    const initialize = async () => {

        setDataSource([])
        let data = await ajaxService.get('categories')
        if (data) {
            setDataSource(data)
        }
    }

    useEffect(() => {
        initialize()
    }, [])

    const renderHeader = () => {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Title level={3}>Categories</Title>
                    <Space size="small" >
                        <Button className='addBtn' type="primary" onClick={() => history.push({ pathname: '/categories/form/0' })}>Add Category</Button>
                    </Space>
                </div>
            </>
        )
    }

    const renderModal = () => {
        return (
            <Modal
                title="Delete Category"
                centered
                visible={showModal}
                onCancel={() => setShowModal(false)}
                footer={[
                    <Button type='primary' danger key="delete" onClick={() => deleteRow()}
                    >
                        Delete
                    </Button>,
                    <Button key="back" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                ]}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>Are you sure you want to delete this category?</p>
                </div>
            </Modal>
        )
    }

    const renderCategories = () => {
        return (
            <>
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    title={() => renderHeader()}
                />
            </>
        );
    }
    // For Mobile
    const renderCategoriesMobile = () => {
        return (
            <>
                {
                    dataSource.map(category => {
                        return (
                            <>
                                <br />
                                <Card
                                    style={{ width: 330 }}
                                    actions={[
                                        <Space size='small'>
                                            <Button type='primary' icon={<EditOutlined />} onClick={() => history.push({ pathname: '/categories/form/' + category.id })}>Edit</Button>
                                            <Button type='primary' danger icon={<DeleteOutlined />} onClick={() => openDeleteModal(category.id)}>Delete</Button>
                                        </Space>
                                    ]}>
                                    <Text style={{ fontWeight: 'bolder' }}>Name : <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>{category.name}</span></Text>
                                    <br /><br />
                                    <Text style={{ fontWeight: 'bolder' }}>Active :
                                        <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                            <Switch
                                                onChange={(val) => updateValue(category.id, val, 'active')}
                                                checkedChildren={<CheckOutlined />}
                                                unCheckedChildren={<CloseOutlined />}
                                                defaultChecked={category.active == 1 ? true : false}
                                            />
                                        </span>
                                    </Text>
                                    <br />
                                </Card>
                                <br />
                            </>
                        );
                    })
                }
            </>
        )
    }

    const renderContent = () => {
        if (isMobile) {
            return renderCategoriesMobile()
        } else {
            return renderCategories()
        }
    }

    return (
        <>
            {isMobile && renderHeader()}
            {renderContent()}
            {renderModal()}
        </>
    )
}

export default Categories