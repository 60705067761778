import { Button, Card, Col, Row } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
const PandingAproval = () => {

    let history = useHistory()
    const location = useLocation();
    return (
        <div div className="site-card-border-less-wrapper" >
            <br /><br /><br />
            <Row>
                <Col span={8}></Col>
                <Card
                    title="Admin Aproval"
                    bordered={false}
                    style={{
                        width: 400,
                    }}
                    actions={[
                        <Button type='primary' onClick={() => history.push({ pathname: '/login' })}>Back to login</Button>
                    ]}
                >
                    <p>{location.search ? 'Your account is' : 'You are successfully'} registered please contact admin for approval 0322-2093633.</p>
                </Card>
            </Row>
        </div>
    )
}
export default PandingAproval;