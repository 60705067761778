import { Button, Col, Form, Input, notification, Row, Space, Switch } from "antd";
import { CheckOutlined, CloseOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ajaxService from "../../services/ajax-services";

const CategoryForm = () => {

    const [form] = Form.useForm();
    const { id } = useParams()
    let history = useHistory()

    const onFinish = async (values) => {
        if (id == 0) {
            const response = await ajaxService.post('categories', values)
            if (response) {
                history.push({ pathname: "/categories" });
                notification.open({ message: 'Category has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to add category', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }

        } else {
            let response = await ajaxService.put('categories/', values, id)
            if (response) {
                history.push({ pathname: "/categories" });
                notification.open({ message: 'Category has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to updated category', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }

        }

    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const loadData = async (id) => {
        if (id > 0) {
            let data = await ajaxService.get('categories/' + id)
            form.setFieldsValue({ ...data })
        }
    }

    useEffect(() => {
        loadData(id)
    }, [])

    return (
        <>
            <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
                initialValues={{
                    active: true
                }}>
                <Row gutter={16} >
                    <Col span={6}></Col>
                    <Col span={2}></Col>
                    <Col span={12} >
                        <Row gutter={16}>
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[{ required: true, message: "Name is required" }]}
                            >
                                <Input placeholder="Category Name" />
                            </Form.Item>
                            <Col span={1}></Col>
                            <Form.Item name="active" label="Active" valuePropName="checked">
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked
                                />
                            </Form.Item>
                        </Row>
                        <Row></Row>
                        <Row>
                            <Form.Item>
                                <Space>
                                    <Button htmlType="submit" type="primary">Submit</Button>
                                    <Button onClick={() => history.goBack()}>Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Row>
                    </Col>
                    <Col span={6}></Col>
                </Row>
            </Form>
        </>
    )
}

export default CategoryForm