import { Button, Card, Col, Form, Input, notification, Row } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ajaxService from "../../services/ajax-services";
import { CloseCircleOutlined } from '@ant-design/icons';
import { useWindowSize } from "../../hooks";

function PasswordReset() {


    const { width } = useWindowSize()

    const isMobile = width <= 768
    let history = useHistory()
    const [form] = Form.useForm();
    let { token } = useParams();
    const location = useLocation();
    let str = location.search.replace("?email=", "");
    const onFinish = async (values) => {
        let data = {
            email: values.email,
            password: values.password,
            token: token,
        }
        const response = await ajaxService.post('password/reset', data)
        if (response.errors) {
            notification.open({ message: response.errors.email, icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        } else {
            history.replace({ pathname: "/login", search: '?via=password-reset', })
        }
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const renderPasswordReset = () => {
        return (
            <>
                <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '70px', marginBottom: '70px' }} >
                    <Col span={3}></Col>
                    <Col span={18}>
                        <Row>
                            <Col span={6}></Col>
                            <Col span={8}>
                                <Card title="Reset Password" bordered={false} style={{ width: '100%', }}
                                    actions={[
                                        <a style={{ color: '#4687F2' }} onClick={() => history.push({ pathname: '/register' })}>Need account ? Register here</a>
                                    ]}>
                                    <Form
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                        form={form}
                                        initialValues={{
                                            email: str
                                        }}
                                    >
                                        <Form.Item
                                            labelCol={{ span: 24 }}
                                            label="Email"
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    type: "email",
                                                    message: 'Please input your email',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="Enter email address" />
                                        </Form.Item>

                                        <Form.Item
                                            labelCol={{ span: 24 }}
                                            label="Password"
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    min: 8,
                                                    message: "Password must be at least 8 characters",
                                                },
                                            ]}>
                                            <Input.Password />
                                        </Form.Item>
                                        <Form.Item
                                            labelCol={{ span: 24 }}
                                            label="Confirm Password"
                                            name="confirm_password"
                                            dependencies={['password']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Confirm your password!',
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error("Confirm password does not match"));
                                                    },
                                                }),
                                            ]}>
                                            <Input.Password />
                                        </Form.Item>
                                        <a onClick={() => history.push({ pathname: '/login' })}>Return to login</a>
                                        <br/><br/>
                                        <Button type="primary" style={{marginLeft:'50px'}} htmlType="submit">
                                            Reset Password
                                        </Button>
                                    </Form>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }

    const renderPasswordResetMobile = () => {
        return (
            <>
                <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '70px', marginBottom: '70px' }} >
                    <Col span={24}>
                        <Card title="Reset Password" bordered={false} style={{ width: '100%', }}
                            actions={[
                                <a style={{ color: '#4687F2' }} onClick={() => history.push({ pathname: '/register' })}>Need account ? Register here</a>
                            ]}>
                            <Form
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                form={form}
                                initialValues={{
                                    email: str
                                }}
                            >
                                <Form.Item
                                    labelCol={{ span: 24 }}
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            type: "email",
                                            message: 'Please input your email',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Enter email address" />
                                </Form.Item>

                                <Form.Item
                                    labelCol={{ span: 24 }}
                                    label="Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            min: 8,
                                            message: "Password must be at least 8 characters",
                                        },
                                    ]}>
                                    <Input.Password />
                                </Form.Item>
                                <Form.Item
                                    labelCol={{ span: 24 }}
                                    label="Confirm Password"
                                    name="confirm_password"
                                    dependencies={['password']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Confirm your password!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error("Confirm password does not match"));
                                            },
                                        }),
                                    ]}>
                                    <Input.Password />
                                </Form.Item>
                                <a onClick={() => history.push({ pathname: '/login' })}>Return to login</a>
                                <Button style={{ marginLeft: '6px' }} type="primary" htmlType="submit">
                                    Reset Password
                                </Button>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }

    const renderContent = () => {
        if (isMobile) {
            return renderPasswordResetMobile()
        } else {
            return renderPasswordReset()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )
}
export default PasswordReset