import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Form, Input, Button, Checkbox, Card, Row, Col, notification, } from 'antd';
import ajaxService from '../../services/ajax-services';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useWindowSize } from '../../hooks';

const Register = () => {

    const { width } = useWindowSize()

    const isMobile = width <= 768
    let history = useHistory();
    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/" } };

    const onFinish = async (values) => {
        const response = await ajaxService.post('users/register', values);

        // validation error
        if (response.errors) {
            notification.open({ message: response.errors, icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }

        let { token, user } = response;
        let { verified, id } = user;

        if (response && verified) {
            localStorage.setItem('loggedInUser', id);
            localStorage.setItem('token', token);
            history.replace(from);
        }
        else {
            history.replace({ pathname: "/pandingAproval" })
        }
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const renderRegister = () => {
        return (
            <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '70px', marginBottom: '70px' }} >
                <Col span={3}></Col>
                <Col span={18}>
                    <Row>
                        <Col span={8}>

                        </Col>
                        <Col span={8}>
                            <Card title="Register your account" bordered={false} style={{ width: '100%' }}>
                                <Form
                                    layout={'vertical'}
                                    name="basic"
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}>
                                    <Form.Item
                                        label="Name"
                                        name="name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'name is required',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Email is required',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        label="Password"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                min: 8,
                                                message: "Password must be at least 8 characters",
                                            },
                                        ]}>
                                        <Input.Password />
                                    </Form.Item>

                                    <Form.Item
                                        label="Confirm Password"
                                        name="confirm_password"
                                        dependencies={['password']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Confirm your password!',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error("Confirm password does not match"));
                                                },
                                            }),
                                        ]}>
                                        <Input.Password />
                                    </Form.Item>

                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                        <Button style={{ marginLeft: 7 }} onClick={() => history.push({ pathname: '/login' })}>
                                            Back
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Card>
                        </Col>
                        <Col span={8}>

                        </Col>
                    </Row>

                </Col>
                <Col span={3}></Col>
            </Row>
        );
    }

    const renderRegisterMobile = () => {
        return (
            <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '70px', marginBottom: '70px' }} >

                <Col span={24}>
                    <Card title="Register your account" bordered={false} style={{ width: '100%' }}>
                        <Form
                            layout={'vertical'}
                            name="basic"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}>
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'name is required',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Email is required',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        min: 8,
                                        message: "Password must be at least 8 characters",
                                    },
                                ]}>
                                <Input.Password />
                            </Form.Item>

                            <Form.Item
                                label="Confirm Password"
                                name="confirm_password"
                                dependencies={['password']}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error("Confirm password does not match"));
                                        },
                                    }),
                                ]}>
                                <Input.Password />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                                <Button style={{ marginLeft: 7 }} onClick={() => history.push({ pathname: '/login' })}>
                                    Back
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        );
    }

    const renderContent = () => {
        if (isMobile) {
            return renderRegisterMobile()
        } else {
            return renderRegister()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )
};

export default Register