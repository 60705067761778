import { Button, Card, Col, DatePicker, Divider, Form, Input, Modal, notification, Radio, Row, Select, Space, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useLocation, } from 'react-router-dom';
import ajaxService from '../../services/ajax-services';
import TextArea from 'antd/es/input/TextArea';
import { useWindowSize } from '../../hooks';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const { Title, Text } = Typography
const { Option } = Select
const { RangePicker } = DatePicker

function Transactions() {

    const dateFormat = 'YYYY-MM-DD';

    const [form] = Form.useForm();
    const location = useLocation();
    const [formtransfer] = Form.useForm();

    const { width } = useWindowSize()

    const isMobile = width <= 768

    const [transferModal, setTransferModal] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [categories, setCategories] = useState([])
    const [accounts, setAccounts] = useState([])
    const [entities, setEntities] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [FormModal, setFormModal] = useState(false)
    const [categoryFilter, setCategoryFilter] = useState('')
    const [accountFilter, setAccountFilter] = useState('')
    const [entityFilter, setEntityFilter] = useState('')
    const [amountFilter, setAmountFilter] = useState('')
    const [dateRange, setDateRang] = useState('')
    const [Id, setId] = useState(0)
    const [radioValue, setRadioValue] = useState('account')
    const [Submit, setSubmit] = useState('')

    if (FormModal == false) {
        form.resetFields();
    }

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: '20%'
        },
        {
            title: 'Entity',
            dataIndex: 'entity_id',
            key: 'entity_id',
            render: (text, record) => { return <Text >{record.entity_id ? record.entity.name : 'N/A'}</Text> }
        },
        {
            title: 'Category',
            dataIndex: 'category_id',
            key: 'category_id',
            render: (text, record) => { return <Text >{record.category_id ? record.category.name : 'N/A'}</Text> }
        },
        {
            title: 'Account',
            dataIndex: 'account_id',
            key: 'account_id',
            render: (text, record) => { return <Text >{record.account_id ? record.account.name : 'N/A'}</Text> }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: text => (
                <p style={{ color: text > 0 ? 'green' : 'red' }} >
                    {text}
                </p >
            ),
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            render: (balance) => { return <Text>{balance.toFixed(2)}</Text> }
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return (
                    <Space size='small'>
                        <Button type='primary' onClick={() => openFormModal(record.id)}>Edit</Button>
                        <Button type='primary' danger onClick={() => openDeleteModal(record.id)}>Delete</Button>
                    </Space>)
            }
        },
    ]

    const onFinish = async (values) => {
        let data = {
            account_id: values.account_id,
            category_id: values.category_id,
            entity_id: values.entity_id,
            date: values.date.format(dateFormat),
            amount: values.amount,
            description: values.description,
        }

        if (Id > 0) {
            let response = await ajaxService.put('transactions/', data, Id)
            if (response) {
                setFormModal(false)
                form.resetFields();
                initialize()
                notification.open({ message: 'Transaction has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to updated transaction', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
        else {
            const response = await ajaxService.post('transactions', data)
            if (Submit === 'submitNext') {
                setSubmit('')
                form.resetFields();
                initialize()
            } else {
                if (response) {
                    setFormModal(false)
                    initialize()
                    notification.open({ message: 'Transaction has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
                } else {
                    notification.open({ message: 'Unable to add transaction', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
                }
            }
        }
    };

    const onFinishTransfer = async (values) => {
        let data = {
            account_id1: values.account_id1,
            account_id2: values.account_id2,
            entity_id1: values.entity_id1,
            entity_id2: values.entity_id2,
            date: values.date.format(dateFormat),
            amount: values.amount,
            category_id: values.category_id,
            description: values.description,
        }

        if (Id > 0) {
            let response = await ajaxService.put('transactions/', data, Id)

            if (response) {
                setTransferModal(false)
                initialize()
                notification.open({ message: 'Transaction has been updated successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to updated transaction', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        } else {
            const response = await ajaxService.post('transactions', data)
            if (response) {
                setTransferModal(false)
                initialize()
                notification.open({ message: 'Transaction has been added successfully', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
            } else {
                notification.open({ message: 'Unable to add transaction', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            }
        }
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const handleSearch = async () => {
        var formData = {};

        if (accountFilter !== '') {
            formData['account'] = accountFilter;
        }
        if (categoryFilter !== '') {
            formData['category'] = categoryFilter;
        }
        if (entityFilter !== '') {
            formData['entity'] = entityFilter;
        }
        if (dateRange !== '') {
            formData['dateRange'] = dateRange;
        }
        if (amountFilter !== '') {
            formData['amount'] = amountFilter;
        }

        const response = await ajaxService.filter('transactions/filter', formData)
        if (response !== undefined) {
            setDataSource(response);
        } else {
            notification.open({ message: 'No transaction found...', icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }

    }

    const clear = () => {
        setCategoryFilter('')
        setAccountFilter('')
        setEntityFilter('')
        setAmountFilter('')
        setDateRang('')
    }

    const deleteRow = async () => {
        let transaction_id = localStorage.getItem('transaction')
        let response = await ajaxService.delete('transactions/', transaction_id)
        if (response) {
            setShowModal(false)
            initialize()
            notification.open({ message: 'Transaction has been Deleted Successfully...', icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        }
    }

    const openDeleteModal = (id) => {
        localStorage.setItem('transaction', id);
        setShowModal(true)
    }

    const loadAccounts = async () => {
        let response = await ajaxService.get('accounts')
        if (response) {
            let data = response.filter(i => i.active == 1);
            setAccounts(data.sort((a, b) => a.name.localeCompare(b.name)))
        }
    }

    const loadCategories = async () => {
        let response = await ajaxService.get('categories')
        if (response) {
            let data = response.filter(i => i.active == 1)
            setCategories(data.sort((a, b) => a.name.localeCompare(b.name)))
        }
    }

    const loadEntities = async () => {
        let response = await ajaxService.get('entities')
        if (response) {
            let data = response.filter(i => i.active == 1)
            setEntities(data.sort((a, b) => a.name.localeCompare(b.name)))
        }

    }

    const loadData = async (id) => {
        if (id > 0) {
            let response = await ajaxService.get('transactions/' + id)
            if (response != null) {
                let data = { ...response }
                data.date = dayjs(data.date, dateFormat)
                form.setFieldsValue({ ...data })
            }
        }
    }

    const dashboardFilter = () => {
        let { id, type } = location;
        if (type == 'account') {
            setAccountFilter(id)
        } else {
            setEntityFilter(id)
        }
    }

    const initialize = async () => {
        let data = await ajaxService.get('transactions')
        if (data) {
            setDataSource(data)
        }
    }

    useEffect(() => {
        handleSearch()
    }, [accountFilter, entityFilter])


    useEffect(() => {
        initialize()
        loadCategories()
        loadAccounts()
        loadEntities()
        dashboardFilter()
    }, [])

    const openFormModal = (id) => {
        let row = dataSource.find(i => i.id == id)

        if (id > 0) {
            if (row.transaction_id != null) {
                let internalTransfer = dataSource.filter(i => i.transaction_id == row.transaction_id)

                const from = internalTransfer.find((x) => x.amount < 0);
                const to = internalTransfer.find((x) => x.amount >= 0);

                let data = { ...row }
                data.date = dayjs(data.date, dateFormat)
                formtransfer.setFieldsValue({ ...data })

                if (!row.account_id) {
                    setRadioValue('entity')
                    formtransfer.setFieldsValue({ entity_id1: from.entity_id, entity_id2: to.entity_id })
                } else {
                    setRadioValue('account')
                    formtransfer.setFieldsValue({ account_id1: from.account_id, account_id2: to.account_id })
                }

                setId(id)
                setTransferModal(true)
            } else {
                setId(id)
                loadData(id)
                setFormModal(true)
            }
        } else {
            setId(id)
            setFormModal(true)
        }
    }

    const onChange = (e) => {
        setRadioValue(e.target.value);
        if (radioValue == 'entity') {
            formtransfer.resetFields();
        }
        if (radioValue == 'account') {
            formtransfer.resetFields();
        }
    };

    const renderFilter = () => {
        return (
            <Form layout={'vertical'}>
                <Row gutter={16} >
                    <Col span={6} >
                        <Form.Item label="Accounts" >
                            <Select
                                value={accountFilter != '' ? accountFilter : null}
                                placeholder="Select Account"
                                onChange={(e) => setAccountFilter(e)}
                                showSearch allowClear
                                style={{ width: '100%' }}
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {accounts.map(account => {
                                    return <Option value={account.id}>{account.name}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6} >
                        <Form.Item label="Entities" >
                            <Select
                                value={entityFilter == '' ? null : entityFilter}
                                placeholder="Select Entity"
                                onChange={(e) => setEntityFilter(e)}
                                showSearch allowClear
                                style={{ width: '100%' }}
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {entities.map(entities => {
                                    return <Option value={entities.id}>{entities.name}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Categories" >
                            <Select
                                value={categoryFilter == '' ? null : categoryFilter}
                                placeholder="Select Category"
                                onChange={(e) => setCategoryFilter(e)}
                                showSearch allowClear
                                style={{ width: '100%' }}
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {categories.map(category => {
                                    return <Option value={category.id}>{category.name}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Amount">
                            <Input
                                value={amountFilter != '' ? amountFilter : ''}
                                onChange={(e) => setAmountFilter(e.target.value)}
                                type='number' step="0.01" placeholder='Enter Amount' />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="Date Range" >
                            <RangePicker value={dateRange} onChange={(e) => setDateRang(e)} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="&nbsp;">
                            <Space>
                                <Button type='primary' onClick={handleSearch}>Search</Button>
                                <Button onClick={clear}>Clear</Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form >
        )
    }

    const renderHeader = () => {
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Title level={3}>Transactions</Title>
                    <Space size="small" >
                        <Button className='addBtn' type="primary"
                            onClick={() => {
                                setTransferModal(true);
                                formtransfer.resetFields();
                                setRadioValue('account')
                                setId(0)
                            }}>
                            Internal Transafer
                        </Button>
                        <Button className='addBtn' type="primary" onClick={() => openFormModal(0)}>Add Transaction</Button>
                    </Space>
                </div>
            </>
        )
    }

    const submitNext = () => {
        setSubmit('submitNext')
        if (Submit === 'submitNext') {
            // console.warn(Submit)
            form.submit()
        }
        else {
            console.log('error')
        }
    }

    const formModal = () => {
        return (
            <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
            >
                <Modal
                    width="50%"
                    title="Form"
                    centered
                    visible={FormModal}
                    onCancel={() => setFormModal(false)}
                    footer={[
                        <>
                            <Button type='primary' key="delete" onClick={form.submit}>
                                Submit
                            </Button>
                            <Button type='primary' style={{ background: '#09AE50', display: Id > 0 ? 'none' : '' }} key="delete" onClick={() => submitNext()}>
                                Submit & Next
                            </Button>
                            <Button key="back" onClick={() => setFormModal(false)}>
                                Close
                            </Button>
                        </>
                    ]} >
                    <Row>
                        <Col span={12}>
                            <Form.Item name='account_id' label="Account"
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Account is required',
                                    },
                                ]}>
                                <Select
                                    value={accountFilter != '' ? accountFilter : null}
                                    placeholder="Select Account"
                                    showSearch allowClear
                                    style={{ width: '100%' }}
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {accounts.map(account => {
                                        return <Option value={account.id}>{account.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={11}>
                            <Form.Item name='entity_id' label="Entity"
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Entity is required',
                                    },
                                ]}>
                                <Select
                                    value={entityFilter == '' ? null : entityFilter}
                                    placeholder="Select Entity"
                                    showSearch allowClear
                                    style={{ width: '100%' }}
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {entities.map(entities => {
                                        return <Option value={entities.id}>{entities.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='category_id' label="Category" labelCol={{ span: 24 }}>
                                <Select
                                    value={categoryFilter == '' ? null : categoryFilter}
                                    placeholder="Select Category"
                                    showSearch allowClear
                                    style={{ width: '100%' }}
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {categories.map(category => {
                                        return <Option value={category.id}>{category.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={11}>
                            <Form.Item name='amount' label="Amount"
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Amount is required',
                                    },
                                ]}>
                                <Input placeholder='Enter Amount' type='number' step="0.01" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='date' label="Date"
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Date is required',
                                    },
                                ]}>
                                <DatePicker style={{ width: '100%' }} format={dateFormat} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='description' label="Description"
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Description is required',
                                    },
                                ]}>
                                <TextArea></TextArea>
                            </Form.Item>
                        </Col>
                    </Row>

                </Modal>
            </Form >
        )
    }

    const transferFormModal = () => {
        return (
            <Form
                onFinish={onFinishTransfer}
                onFinishFailed={onFinishFailed}
                form={formtransfer}
            >

                <Modal
                    width="50%"
                    title="Internal Transfer"
                    centered
                    visible={transferModal}
                    onCancel={() => setTransferModal(false)}
                    footer={[
                        <>
                            <Button type='primary' key="delete" onClick={formtransfer.submit}>
                                Submit
                            </Button>
                            <Button key="back" onClick={() => setTransferModal(false)}>
                                Close
                            </Button>
                        </>
                    ]} >
                    <br />
                    <Radio.Group onChange={onChange} value={radioValue}>
                        <Radio value='account'>Account</Radio>
                        <Radio value='entity'>Entity</Radio>
                    </Radio.Group>
                    <br /><br />
                    <Row>
                        <Col span={11}>
                            <Form.Item name='account_id1' label='Account'
                                style={{ display: radioValue == 'entity' && 'none' }}
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: radioValue == 'account' ? true : false,
                                        message: 'Account is required',
                                    },
                                ]}>
                                <Select
                                    placeholder="Select Account"
                                    showSearch allowClear
                                    style={{ width: '100%' }}
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {accounts.map(account => {
                                        return <Option value={account.id}>{account.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>

                            <Form.Item name='entity_id1' label='Entity'
                                style={{ display: radioValue == 'account' && 'none' }}
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: radioValue == 'entity' ? true : false,
                                        message: 'Entity is required',
                                    },
                                ]}>
                                <Select
                                    value={entityFilter == '' ? null : entityFilter}
                                    placeholder="Select Entity"
                                    showSearch allowClear
                                    style={{ width: '100%', display: radioValue == 'account' && 'none' }}
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {entities.map(entities => {
                                        return <Option value={entities.id}>{entities.name}</Option>
                                    })}
                                </Select>

                            </Form.Item>
                        </Col>

                        <Col span={2}>
                            <br />
                            <p style={{ position: 'relative', left: '15px', top: '5px' }}>To</p>
                        </Col>

                        <Col span={11}>
                            <Form.Item name='account_id2' label='Account'
                                style={{ display: radioValue == 'entity' && 'none' }}
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: radioValue == 'account' ? true : false,
                                        message: 'Account is required',
                                    },
                                ]}>
                                <Select
                                    value={accountFilter != '' ? accountFilter : null}
                                    placeholder="Select Account"
                                    showSearch allowClear
                                    style={{ width: '100%' }}
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {accounts.map(account => {
                                        return <Option value={account.id}>{account.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>

                            <Form.Item name='entity_id2' label='Entity'
                                style={{ display: radioValue == 'account' && 'none' }}
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: radioValue == 'entity' ? true : false,
                                        message: 'Entity is required',
                                    },
                                ]}>
                                <Select
                                    value={entityFilter == '' ? null : entityFilter}
                                    placeholder="Select Entity"
                                    showSearch allowClear
                                    style={{ width: '100%', display: radioValue == 'account' && 'none' }}
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {entities.map(entities => {
                                        return <Option value={entities.id}>{entities.name}</Option>
                                    })}
                                </Select>

                            </Form.Item>
                        </Col>
                        <Col span={11}>
                            <Form.Item name='category_id' label="Category" labelCol={{ span: 24 }}>
                                <Select
                                    value={categoryFilter == '' ? null : categoryFilter}
                                    placeholder="Select Category"
                                    showSearch allowClear
                                    style={{ width: '100%' }}
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {categories.map(category => {
                                        return <Option value={category.id}>{category.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <Form.Item name='date' label="Date"
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Date is required',
                                    },
                                ]}>
                                <DatePicker style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='amount' label="Amount"
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Amount is required',
                                    },
                                ]}>
                                <Input placeholder='Enter Amount' type='number' step="0.01" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item name='description' label="Description"
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Description is required',
                                    },
                                ]}>
                                <TextArea></TextArea>

                            </Form.Item>
                        </Col>
                    </Row>

                </Modal>
            </Form >
        )
    }

    const renderTransactions = () => {
        return (
            <>
                {renderFilter()}
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    title={() => renderHeader()}
                    pagination={
                        {
                            defaultPageSize: 50,
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '25', '50', '100', '200', '500', '1000']
                        }
                    }
                />
                {formModal()}
                {transferFormModal()}
                <Modal
                    title="Delete Transaction"
                    centered
                    visible={showModal}
                    onCancel={() => setShowModal(false)}
                    footer={[
                        <Button type='primary' danger key="delete" onClick={() => deleteRow()}>
                            Delete
                        </Button>,
                        <Button key="back" onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                    ]}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p>Are you sure you want to delete this transaction?</p>
                    </div>
                </Modal>
            </>
        );
    }

    // for Mobile

    const renderHeaderMobile = () => {
        return (
            <>
                <div>
                    <Title level={3}>Transactions</Title>
                    <Space size="small" >
                        <Button className='addBtn' type="primary" onClick={() => setTransferModal(true)}>Internal Transafer</Button>
                        <Button className='addBtn' type="primary" onClick={() => openFormModal(0)}>Add Transaction</Button>
                    </Space>
                </div>
            </>
        )
    }

    const renderFilterMobile = () => {
        return (
            <Form layout={'vertical'}>
                <Row gutter={16} >
                    <Col span={24} >
                        <Form.Item label="Accounts" >
                            <Select
                                value={accountFilter != '' ? accountFilter : null}
                                placeholder="Select Account"
                                onChange={(e) => setAccountFilter(e)}
                                showSearch allowClear
                                style={{ width: '100%' }}
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {accounts.map(account => {
                                    return <Option value={account.id}>{account.name}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24} >
                        <Form.Item label="Entities" >
                            <Select
                                value={entityFilter == '' ? null : entityFilter}
                                placeholder="Select Entity"
                                onChange={(e) => setEntityFilter(e)}
                                showSearch allowClear
                                style={{ width: '100%' }}
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {entities.map(entities => {
                                    return <Option value={entities.id}>{entities.name}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Categories" >
                            <Select
                                value={categoryFilter == '' ? null : categoryFilter}
                                placeholder="Select Category"
                                onChange={(e) => setCategoryFilter(e)}
                                showSearch allowClear
                                style={{ width: '100%' }}
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {categories.map(category => {
                                    return <Option value={category.id}>{category.name}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Amount">
                            <Input
                                value={amountFilter != '' ? amountFilter : ''}
                                onChange={(e) => setAmountFilter(e.target.value)}
                                type='number' step="0.01" placeholder='Enter Amount' />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Date Range" >
                            <RangePicker value={dateRange} onChange={(e) => setDateRang(e)} />
                        </Form.Item>
                    </Col>


                    <Col span={6}>
                        <Form.Item label="&nbsp;">
                            <Space>
                                <Button type='primary' onClick={handleSearch}>Search</Button>
                                <Button onClick={clear}>Clear</Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form >
        )
    }

    const formModalMobile = () => {
        return (
            <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}>
                <Modal
                    width="100%"
                    title="Form"
                    centered
                    visible={FormModal}
                    onCancel={() => setFormModal(false)}
                    footer={[
                        <>
                            <Button type='primary' key="delete" onClick={form.submit}>
                                Submit
                            </Button>
                            <Button key="back" onClick={() => setFormModal(false)}>
                                Close
                            </Button>
                        </>
                    ]} >
                    <Row>
                        <Col span={24}>
                            <Form.Item name='account_id' label="Account"
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Account is required',
                                    },
                                ]}>
                                <Select
                                    value={accountFilter != '' ? accountFilter : null}
                                    placeholder="Select Account"
                                    showSearch allowClear
                                    style={{ width: '100%' }}
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {accounts.map(account => {
                                        return <Option value={account.id}>{account.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='entity_id' label="Entity"
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Entity is required',
                                    },
                                ]}>
                                <Select
                                    value={entityFilter == '' ? null : entityFilter}
                                    placeholder="Select Entity"
                                    showSearch allowClear
                                    style={{ width: '100%' }}
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {entities.map(entities => {
                                        return <Option value={entities.id}>{entities.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='category_id' label="Category" labelCol={{ span: 24 }}>
                                <Select
                                    value={categoryFilter == '' ? null : categoryFilter}
                                    placeholder="Select Category"
                                    showSearch allowClear
                                    style={{ width: '100%' }}
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {categories.map(category => {
                                        return <Option value={category.id}>{category.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='amount' label="Amount"
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Amount is required',
                                    },
                                ]}>
                                <Input placeholder='Enter Amount' type='number' step="0.01" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='date' label="Date"
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Date is required',
                                    },
                                ]}>
                                <DatePicker style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='description' label="Description"
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Description is required',
                                    },
                                ]}>
                                <TextArea></TextArea>
                            </Form.Item>
                        </Col>
                    </Row>

                </Modal>
            </Form >
        )
    }

    const transferFormModalMobile = () => {
        return (
            <Form
                onFinish={onFinishTransfer}
                onFinishFailed={onFinishFailed}
                form={formtransfer}>
                <Modal
                    width="100%"
                    title="Internal Transfer"
                    centered
                    visible={transferModal}
                    onCancel={() => setTransferModal(false)}
                    footer={[
                        <>
                            <Button type='primary' key="delete" onClick={formtransfer.submit}>
                                Submit
                            </Button>
                            <Button key="back" onClick={() => setTransferModal(false)}>
                                Close
                            </Button>
                        </>
                    ]} >
                    <br />
                    <Radio.Group onChange={onChange} value={radioValue}>
                        <Radio value='account'>Account</Radio>
                        <Radio value='entity'>Entity</Radio>
                    </Radio.Group>
                    <br /><br />
                    <Row>
                        <Col span={24}>
                            <Form.Item name='account_id1' label='Account'
                                style={{ display: radioValue == 'entity' && 'none' }}
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: radioValue == 'entity' ? false : true,
                                        message: 'Account is required',
                                    },
                                ]}>
                                <Select
                                    placeholder="Select Account"
                                    showSearch allowClear
                                    style={{ width: '100%' }}
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {accounts.map(account => {
                                        return <Option value={account.id}>{account.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>

                            <Form.Item name='entity_id1' label='Entity'
                                style={{ display: radioValue == 'account' && 'none' }}
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: radioValue == 'account' ? false : true,
                                        message: 'Entity is required',
                                    },
                                ]}>
                                <Select
                                    value={entityFilter == '' ? null : entityFilter}
                                    placeholder="Select Entity"
                                    showSearch allowClear
                                    style={{ width: '100%', display: radioValue == 'account' && 'none' }}
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {entities.map(entities => {
                                        return <Option value={entities.id}>{entities.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <p>To</p>
                        </Col>

                        <Col span={24}>
                            <Form.Item name='account_id2' label='Account'
                                style={{ display: radioValue == 'entity' && 'none' }}
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: radioValue == 'entity' ? false : true,
                                        message: 'Account is required',
                                    },
                                ]}>
                                <Select
                                    value={accountFilter != '' ? accountFilter : null}
                                    placeholder="Select Account"
                                    showSearch allowClear
                                    style={{ width: '100%' }}
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {accounts.map(account => {
                                        return <Option value={account.id}>{account.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>

                            <Form.Item name='entity_id2' label='Entity'
                                style={{ display: radioValue == 'account' && 'none' }}
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: radioValue == 'account' ? false : true,
                                        message: 'Entity is required',
                                    },
                                ]}>
                                <Select
                                    value={entityFilter == '' ? null : entityFilter}
                                    placeholder="Select Entity"
                                    showSearch allowClear
                                    style={{ width: '100%', display: radioValue == 'account' && 'none' }}
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {entities.map(entities => {
                                        return <Option value={entities.id}>{entities.name}</Option>
                                    })}
                                </Select>

                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='category_id' label="Category" labelCol={{ span: 24 }}>
                                <Select
                                    value={categoryFilter == '' ? null : categoryFilter}
                                    placeholder="Select Category"
                                    showSearch allowClear
                                    style={{ width: '100%' }}
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {categories.map(category => {
                                        return <Option value={category.id}>{category.name}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='date' label="Date"
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Date is required',
                                    },
                                ]}>
                                <DatePicker style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name='amount' label="Amount"
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Amount is required',
                                    },
                                ]}>
                                <Input placeholder='Enter Amount' type='number' step="0.01" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item name='description' label="Description"
                                labelCol={{ span: 24 }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Description is required',
                                    },
                                ]}>
                                <TextArea></TextArea>

                            </Form.Item>
                        </Col>
                    </Row>

                </Modal>
            </Form >
        )
    }

    const renderTransactionsMobile = () => {
        return (
            <>
                {renderHeaderMobile()}
                <br />
                {renderFilterMobile()}
                <Divider />
                <>
                    {
                        dataSource.map(transaction => {
                            return (
                                <>
                                    <br />
                                    <Card
                                        style={{ width: 330 }}
                                        actions={[
                                            <Space size='small'>
                                                <Button type='primary' icon={<EditOutlined />} onClick={() => openFormModal(transaction.id)}>Edit</Button>
                                                <Button type='primary' danger icon={<DeleteOutlined />} onClick={() => openDeleteModal(transaction.id)}>Delete</Button>
                                            </Space>
                                        ]}>
                                        <Text style={{ fontWeight: 'bolder' }}>Date :
                                            <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                                {transaction.date}
                                            </span>
                                        </Text>
                                        <br />
                                        <Text style={{ fontWeight: 'bolder' }}>Description :
                                            <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                                {transaction.description}
                                            </span>
                                        </Text>
                                        <br />
                                        <Text style={{ fontWeight: 'bolder' }}>Entity :
                                            <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                                {transaction.entity_id ? transaction.entity.name : 'N/A'}
                                            </span>
                                        </Text>
                                        <br />
                                        <Text style={{ fontWeight: 'bolder' }}>Caregory :
                                            <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                                {transaction.category_id ? transaction.category.name : 'N/A'}
                                            </span>
                                        </Text>
                                        <br />
                                        <Text style={{ fontWeight: 'bolder' }}>Account :
                                            <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                                {transaction.account_id ? transaction.account.name : 'N/A'}
                                            </span>
                                        </Text>
                                        <br />
                                        <Text style={{ fontWeight: 'bolder' }}>Amount :
                                            <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                                {transaction.amount}
                                            </span>
                                        </Text>
                                        <br />
                                        <Text style={{ fontWeight: 'bolder' }}>Balance :
                                            <span style={{ fontWeight: 'lighter', marginLeft: 10 }}>
                                                {transaction.balance.toFixed(2)}
                                            </span>
                                        </Text>
                                        <br />
                                    </Card>
                                    <br />
                                </>
                            );
                        })
                    }
                </>
                {formModalMobile()}
                {transferFormModalMobile()}
            </>
        )
    }

    const renderContent = () => {
        if (isMobile) {
            return renderTransactionsMobile()
        } else {
            return renderTransactions()
        }
    }

    return (
        <>
            {renderContent()}
        </>
    )
}

export default Transactions