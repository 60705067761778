import React from 'react';
import { Link, useHistory } from "react-router-dom";
import { Layout, Menu } from 'antd';
import {
    AppstoreAddOutlined,
    AccountBookOutlined,
    PullRequestOutlined,
    ReadOutlined,
    LinkOutlined,
    LoginOutlined,
    UserOutlined
} from '@ant-design/icons';

const { Header } = Layout;

export default function MainHeader() {

    let history = useHistory()
    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('loggedInUser');
        history.push({ pathname: '/' })
    }

    return (
        <Header style={{ background: '#003366' }} >
            <Menu mode="horizontal" style={{ width: '100%', background: '#003366', color: '#FFF' }} >
                <Menu.Item key="1" className="headerMenuItems" icon={<AppstoreAddOutlined />}><Link to={'/'}>Dashboard</Link></Menu.Item>
                <Menu.Item key="2" className="headerMenuItems" icon={<ReadOutlined />}><Link to={'/categories'}>Categories</Link></Menu.Item>
                <Menu.Item key="3" className="headerMenuItems" icon={<LinkOutlined />}><Link to={'/entities'}>Entities</Link></Menu.Item>
                <Menu.Item key="4" className="headerMenuItems" icon={<AccountBookOutlined />}><Link to={'/accounts'}>Accounts</Link></Menu.Item>
                <Menu.Item key="5" className="headerMenuItems" icon={<PullRequestOutlined />}><Link to={'/transactions'}>Transactions</Link></Menu.Item>
                <Menu.Item key="6" className="headerMenuItems" icon={<UserOutlined />}><Link to={'/change-password'}>Change Paaword</Link></Menu.Item>
                <Menu.Item key="7" onClick={logout} className="headerMenuItems" icon={<LoginOutlined />}>Logout</Menu.Item>

            </Menu>
        </Header >
    );
}